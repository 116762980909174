import * as React from "react";
import Layout from "../layout/Layout";
import { graphql } from "gatsby";
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image";
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";

interface IndexPageProps {
  data: {
    contentfulCourse: {
      heroImage: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    courseInfo: {
      title: string;
      richText: RenderRichTextData<ContentfulRichTextGatsbyReference>;
      contentful_id: string;
    };
  };
}
const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  return (
    <Layout title="Home">
      <GatsbyImage
        image={data.contentfulCourse.heroImage.gatsbyImageData}
        alt="Helsinki"
        className="h-[30vh] mx-auto"
      />
      <p className="text-2xl font-light mt-3 text-center text-zinc-300">
        Welcome to
      </p>
      <h2 className="text-center font-nordter text-red-700 text-4xl">
        The 12th Nordic Course in Trauma Radiology
      </h2>
      <div className="contentful-rich-text mt-4">
        {renderRichText(data.courseInfo.richText, {
          renderNode: {
            [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
              const id = node.data.target.sys.id;
              const asset = data.courseInfo.richText.references.find(
                (refe) => refe.contentful_id === id
              );
              return (
                <a href={asset?.file.url} target="_blank">
                  {children}
                </a>
              );
            },
            [BLOCKS.HEADING_1]: (node: any, children: any) => (
              <div>
                <h1 className="display-4 text-center mb-4">{children}</h1>
              </div>
            ),
            [BLOCKS.HEADING_2]: (node: any, children: any) => (
              <div>
                <h2 className="display-4 text-center mb-4">{children}</h2>
              </div>
            ),
            [BLOCKS.HEADING_3]: (node: any, children: any) => (
              <div>
                <h3 className="text-2xl mt-3">{children}</h3>
              </div>
            ),
            [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
              return <p className="mt-4">{children}</p>;
            },
            [BLOCKS.LIST_ITEM]: (node: any, children: any) => {
              const transformedChildren = renderRichText(
                {
                  raw: JSON.stringify(node),
                } as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                {
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (_node, _children) => _children,
                    [BLOCKS.LIST_ITEM]: (_node, _children) => _children,
                  },
                }
              );

              return <li className="mt-2">{transformedChildren}</li>;
            },
            [BLOCKS.UL_LIST]: (node: any, children: any) => (
              <ul className="list-disc list-inside">{children}</ul>
            ),
          },
          renderText: (text) => {
            return (
              text
                .split("\n")
                //@ts-ignore
                .reduce((children, textSegment, index) => {
                  return [
                    ...children,
                    index > 0 && <br key={index} />,
                    textSegment,
                  ];
                }, [])
            );
          },
        })}
      </div>
    </Layout>
  );
};

export default IndexPage;

export const indexQuery = graphql`
  query IndexPageQuery {
    contentfulCourse(courseId: { eq: "TRA2024COURSE" }) {
      heroImage {
        gatsbyImageData(layout: CONSTRAINED, width: 1536, placeholder: BLURRED)
      }
    }
    courseInfo: contentfulDocument(title: { glob: "*Trauma Radiology 2024" }) {
      title
      richText {
        raw
      }
      contentful_id
    }
  }
`;
